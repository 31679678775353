import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  Paragraph,
  PlatformTab,
  Section,
  SectionSubhead,
  PropList,
  PropListItem,
  PageNavigation,
  PageNavigationLink,
  DataTable,
  DataTableRow,
  DataTableHeader,
  DataTableBody,
  DataTableValueBlock,
  DataTableCodeBlock,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';

const WebTab = ({ data }) => {
  return (
    <PlatformTab>
      <PageNavigation>
        <PageNavigationLink>Mixin</PageNavigationLink>
        <PageNavigationLink>Margin Classes</PageNavigationLink>
        <PageNavigationLink>Padding Classes</PageNavigationLink>
      </PageNavigation>

      <Section title="Mixin">
        <Paragraph>Used to create standard buttons.</Paragraph>

        <CodeSnippet
          code="@include space(margin, vert, $space-two);"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.buttons.scss#L541"
        />

        <Section>
          <SectionSubhead>Parameters</SectionSubhead>
          <PropList>
            <PropListItem name="$property" types={['string']}>
              <Text>
                Default value: <code>margin</code>
              </Text>
              <Text>Pass one of the following values</Text>
              <List type="unordered">
                <li>
                  <code>margin</code>
                </li>
                <li>
                  <code>padding</code>
                </li>
              </List>
            </PropListItem>

            <PropListItem name="$direction" types={['string']}>
              <Text>
                Default value: <code>all</code>
              </Text>
              <Text>Pass one of the following values</Text>
              <List type="unordered">
                <li>
                  <code>all</code>
                </li>
                <li>
                  <code>vertical</code>
                </li>
                <li>
                  <code>horizontal</code>
                </li>
                <li>
                  <code>top</code>
                </li>
                <li>
                  <code>right</code>
                </li>
                <li>
                  <code>bottom</code>
                </li>
                <li>
                  <code>left</code>
                </li>
              </List>
            </PropListItem>

            <PropListItem name="$size" types={['number']}>
              <Text>
                Default value: <code>$space-one</code>
              </Text>
              <Text>
                Pass one of the{' '}
                <Link href="/resources/variables/space?web">
                  space variables
                </Link>
                .
              </Text>
            </PropListItem>
          </PropList>
        </Section>
      </Section>
      {data.allSpaceClassesYaml.edges[0].node.types.map(property => {
        return (
          <Section title={property.name + ' Classes'} key={property.name}>
            <Paragraph>{property.description}</Paragraph>
            {data.allSpaceClassesYaml.edges[0].node.directions.map(item => {
              return (
                <div className="uni-margin--two--top" key={item.name}>
                  <SectionSubhead>{item.name}</SectionSubhead>
                  <DataTable>
                    <DataTableHeader>
                      <DataTableRow layout="thirds">
                        <th>Class Name</th>
                        <th>Value</th>
                        <th>Associated Variable</th>
                      </DataTableRow>
                    </DataTableHeader>
                    <DataTableBody>
                      {data.allSpaceClassesYaml.edges[0].node.sizes.map(row => {
                        let direction;
                        if (item.direction !== null) {
                          direction = '--' + item.direction;
                        } else {
                          direction = '';
                        }
                        const classValue =
                          'uni-' +
                          property.property +
                          '--' +
                          row.name +
                          direction;
                        return (
                          <DataTableRow layout="thirds" key={row.sass_var}>
                            <DataTableCodeBlock>
                              {classValue}
                            </DataTableCodeBlock>
                            <DataTableValueBlock>
                              {row.value}
                            </DataTableValueBlock>
                            <DataTableCodeBlock>
                              {row.sass_var}
                            </DataTableCodeBlock>
                          </DataTableRow>
                        );
                      })}
                    </DataTableBody>
                  </DataTable>
                </div>
              );
            })}
          </Section>
        );
      })}
    </PlatformTab>
  );
};

export default WebTab;
